// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from 'common/components/Button';
import BuyTicketsButtonContainer from 'marketing/containers/BuyTicketsButtonContainer';

// Styles
import { hero,
  background,
  content,
  main,
  sub,
  logo } from './styles.module.css';

const Hero = ({
  mainHeading,
  subHeading,
  backgroundUrl,
  logoUrl,
  buttonTo,
  buttonText,
  orderTickets,
}) => (
  <div className={`${hero} theme-background-primary`}>
    <div
      className={background}
      style={backgroundUrl && { backgroundImage: `url( ${backgroundUrl} )` }}
    />
    <div className={content}>
      {subHeading && <p className={`${sub} h2`}>{subHeading}</p>}

      {mainHeading && (
        <h1 className={`${main} headingFont alt-hero`}>{mainHeading}</h1>
      )}

      <div style={{ flexGrow: 1 }} />

      {logoUrl && <img className={logo} src={logoUrl} alt="logo" />}

      <div style={{ flexGrow: 1 }} />

      {orderTickets && (
        <>
          <BuyTicketsButtonContainer />
          <div style={{ flexGrow: 1 }} />
        </>
      )}

      {buttonTo && buttonText && (
        <>
          <Button
            to={buttonTo}
            text={buttonText}
            modifiers="secondary"
            extraClasses="u-uppercase"
          />
          <div style={{ flexGrow: 1 }} />
        </>
      )}
    </div>
  </div>
);

Hero.propTypes = {
  mainHeading: PropTypes.string.isRequired,
  backgroundUrl: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  logoUrl: PropTypes.string,
  orderTickets: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonTo: PropTypes.string,
};

Hero.defaultProps = {
  subHeading: '',
  buttonTo: '',
  buttonText: '',
  logoUrl: '',
  orderTickets: false,
};

export default Hero;
